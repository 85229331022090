

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class AccordionV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
      const allAccordionElements = this.element.childNodes
      const bodySelector = '.elAccordionItemBody'
      const openClass = 'elAccordionItemOpen'
      const closeClass = 'elAccordionItemClosing'
      allAccordionElements.forEach((accordion) => {
        const header = accordion.querySelector('.elAccordionItemHeader')
        const body = accordion.querySelector(bodySelector)

        header.addEventListener('click', () => {
          const isOpen = accordion.classList.contains(openClass)
          if (isOpen) {
            accordion.classList.add(closeClass)
            $(body).slideUp(300, () => { 
              accordion.classList.remove(openClass)
              accordion.classList.remove(closeClass)
            })
          } else {
            accordion.classList.add(openClass)
            $(body).slideDown(300)
          }
          allAccordionElements.forEach((otherAccordion) => {
            if (otherAccordion === accordion) return
            const body = otherAccordion.querySelector(bodySelector)
            otherAccordion.classList.add(closeClass)
            $(body).slideUp(300, () => { 
              otherAccordion.classList.remove(openClass)
              otherAccordion.classList.remove(closeClass)
            })
          })
        })
      })
    }



}

registerComponent('Accordion/V1', AccordionV1)
window["AccordionV1"] = AccordionV1

